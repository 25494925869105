<template>
    <div class="tasks-list-container">
        <draggable
                v-for="(list, listIndex) in lists" :key="listIndex" :style="`min-width: ${listWidth}px`"
                class="tasks-list"
                tag="div"
                :list="tasksByList[list.name]"
                v-bind="dragOptions"
                group="tasks"
                :sort="false"
                @change="onChange($event, list)"
                @start="drag = true"
                @end="drag = false"
        >
            <transition-group style="min-height: 100px;display: block;" type="transition"
                              :name="!drag ? 'flip-list' : null">
                <task
                        v-for="(element, taskIndex) in tasksByList[list.name]"
                        :template="template"
                        :component-style="taskStyle"
                        :key="taskIndex"
                        :data="element"
                />
            </transition-group>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import Task from '../Task'

export default {
  name: 'tasks-container',
  inject: ['getEventBus'],
  props: {
    template: {
      type: Array,
      default: () => {
        return []
      }
    },
    taskStyle: {
      type: String,
      default: ''
    },
    lists: {
      type: Array,
      default () {
        return []
      }
    },
    tasks: {
      type: Array,
      default () {
        return []
      }
    },
    listWidth: {
      type: Number,
      default: 300
    }
  },
  components: {
    draggable,
    Task
  },
  methods: {
    onChange (event, list) {
      if (Object.keys(event)[0] === 'added') {
        this.getEventBus().$emit('change', {
          element: event[Object.keys(event)[0]],
          list: list
        })
      }
    }
  },
  computed: {
    tasksByList () {
      let tasks = {}
      this.lists.forEach((list) => {
        tasks[list.name] = this.tasks.filter((item) => item.list === list.name)
      })

      return tasks
    }
  },
  data () {
    return {
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  }
}
</script>

<style scoped>
    .tasks-list-container .tasks-list {
        background: #E9EBED;
        min-height: 100px;
        margin-right: 10px;
        border-radius: 8px;
    }

    .tasks-list-container {
        display: flex;
        flex-direction: row;
    }
</style>
